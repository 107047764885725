import { isArray, isString, isUndefined } from "lodash-es"
import { useRouter } from "next/router"
import { UrlObject } from "url"

export const useRedirect = () => {
  const router = useRouter()

  // If multiple redirects are given, only execute the first one
  const redirect = router.query?.redirect
  const redirectUrl = isArray(router.query.redirect)
    ? redirect?.[0]
    : (redirect as string)

  if (!isUndefined(redirectUrl)) {
    // Prefetch the route we're expected to redirect to
    router.prefetch(redirectUrl)
  }

  const generateRedirectUrl = (
    url: UrlObject | string,
    keepRedirect: boolean = false
  ) => {
    const urlObject: UrlObject = isString(url) ? { pathname: url } : url

    if (isString(urlObject.query)) {
      throw new Error("URL Query can only be an object")
    }

    const redirect = keepRedirect && redirectUrl ? redirectUrl : router.asPath

    return {
      ...urlObject,
      pathname: urlObject.pathname,
      query: {
        ...urlObject.query,
        redirect,
      },
    }
  }

  const pushWithRedirect = (
    url: UrlObject | string,
    keepRedirect: boolean = false
  ) => router.push(generateRedirectUrl(url, keepRedirect))

  const executeRedirect = (fallbackRoute: string) => {
    router.push(redirectUrl ?? fallbackRoute)
  }

  return {
    pushWithRedirect,
    executeRedirect,
    generateRedirectUrl,
  }
}
