import { AuthError, signInWithPopup } from "firebase/auth"
import { useState } from "react"
import { useFirebaseAuth } from "../../../utils/authentication/useFirebaseAuth"
import { createFacebookLoginButton } from "./createFacebookLoginButton"
import { createGoogleLoginButton } from "./createGoogleLoginButton"
import { createMicrosoftLoginButton } from "./createMicrosoftLoginButton"
import { getThirdPartyLoginErrorFromFirebaseCode } from "./getThirdPartyLoginErrorFromFirebaseCode"
import { ThirdPartyLoginError } from "./ThirdPartyLoginError"
import { ThirdPartyLoginErrorAlert } from "./ThirdPartyLoginErrorAlert"
import { ThirdPartyLoginErrorType } from "./ThirdPartyLoginErrorType"
import { ThirdPartyLoginHandler } from "./ThirdPartyLoginHandler"
import { ThirdPartyLoginProvider } from "./ThirdPartyLoginProvider"
import { ThirdPartyLoginState } from "./ThirdPartyLoginState"

export const useThirdPartyLoginElements = () => {
  const [thirdPartyLoginState, setThirdPartyLoginState] =
    useState<ThirdPartyLoginState>(ThirdPartyLoginState.IDLE)

  const [thirdPartyLoginProvider, setThirdPartyLoginProvider] =
    useState<ThirdPartyLoginProvider | null>(null)

  const [thirdPartyLoginErrorType, setThirdPartyLoginErrorType] =
    useState<ThirdPartyLoginErrorType>(ThirdPartyLoginErrorType.OTHER)

  const [thirdPartyLoginError, setThirdPartyLoginError] =
    useState<ThirdPartyLoginError>(null)

  const firebaseAuth = useFirebaseAuth()

  const reset = () => {
    setThirdPartyLoginState(ThirdPartyLoginState.IDLE)
    setThirdPartyLoginProvider(null)
    setThirdPartyLoginError(null)
    setThirdPartyLoginErrorType(ThirdPartyLoginErrorType.OTHER)
  }

  const login: ThirdPartyLoginHandler = async (
    provider,
    firebaseAuthProvider
  ) => {
    reset()
    setThirdPartyLoginProvider(provider)

    try {
      setThirdPartyLoginState(ThirdPartyLoginState.POPUP_ACTIVE)
      await signInWithPopup(firebaseAuth, firebaseAuthProvider)
      setThirdPartyLoginState(ThirdPartyLoginState.LOGGED_IN)
    } catch (error) {
      setThirdPartyLoginState(ThirdPartyLoginState.ERROR)
      const errorType = getThirdPartyLoginErrorFromFirebaseCode(error?.code)
      setThirdPartyLoginErrorType(errorType)
      setThirdPartyLoginError(error as AuthError)
      console.error(error)
    }
  }

  const facebookLoginIsPending =
    thirdPartyLoginState === ThirdPartyLoginState.POPUP_ACTIVE &&
    thirdPartyLoginProvider === ThirdPartyLoginProvider.FACEBOOK

  const FacebookLoginButton = createFacebookLoginButton(
    login,
    facebookLoginIsPending
  )

  const googleLoginIsPending =
    thirdPartyLoginState === ThirdPartyLoginState.POPUP_ACTIVE &&
    thirdPartyLoginProvider === ThirdPartyLoginProvider.GOOGLE

  const GoogleLoginButton = createGoogleLoginButton(login, googleLoginIsPending)

  const ssoLoginIsPending =
    thirdPartyLoginState === ThirdPartyLoginState.POPUP_ACTIVE &&
    thirdPartyLoginProvider === ThirdPartyLoginProvider.SSO

  const SSOLoginButton = createMicrosoftLoginButton(login, ssoLoginIsPending)

  const WrappedThirdPartyLoginErrorAlert = () => (
    <ThirdPartyLoginErrorAlert
      provider={thirdPartyLoginProvider}
      type={thirdPartyLoginErrorType}
      error={thirdPartyLoginError}
    />
  )

  return {
    FacebookLoginButton,
    GoogleLoginButton,
    SSOLoginButton,
    thirdPartyLoginState,
    thirdPartyLoginProvider,
    thirdPartyLoginErrorType,
    thirdPartyLoginError,
    resetThirdPartyLoginState: reset,
    ThirdPartyLoginErrorAlert: WrappedThirdPartyLoginErrorAlert,
  }
}
