import { useFormikContext } from "formik"
import { useTranslation } from "next-i18next"
import { FunctionComponent, useEffect } from "react"
import { useYupLocalization } from "../localization/YupLocalization"

// When change language is triggered, re-validate the form as to get all errors translated
// the parameters here are part of the FormikProps<Values> render props
// https://jaredpalmer.com/formik/docs/api/formik#formik-render-methods-and-props
export const useTranslateFormErrors = () => {
  const { errors, touched, setFieldTouched, validateField, ...props } =
    useFormikContext()

  useYupLocalization()
  const { t } = useTranslation()

  useEffect(() => {
    Object.keys(errors).forEach((fieldName) => {
      if (Object.keys(touched).includes(fieldName)) {
        setFieldTouched(fieldName)
      }
    })
  }, [t])
}

export const WithTranslateFormErrors: FunctionComponent = ({ children }) => {
  useTranslateFormErrors()
  return <>{children}</>
}
