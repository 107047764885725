import { Box, Divider } from "@material-ui/core"
import { FunctionComponent } from "react"

export const SplitDivider: FunctionComponent = (props) => (
  <Box display="flex" alignItems="center">
    <Box flexGrow={1}>
      <Divider />
    </Box>
    <Box flexGrow={0} px={2}>
      {props.children}
    </Box>
    <Box flexGrow={1}>
      <Divider />
    </Box>
  </Box>
)
