import { Theme, ThemeProvider } from "@material-ui/core"
import React, { FunctionComponent } from "react"

export const createSSOTheme = (theme: Theme) => ({
  ...theme,
  palette: {
    ...theme.palette,
    primary: { main: "#ffffff" },
  },
})

export const SSOThemeProvider: FunctionComponent = ({ children }) => {
  return (
    <ThemeProvider theme={(theme) => createSSOTheme(theme as Theme)}>
      {children}
    </ThemeProvider>
  )
}
