import { SvgIcon } from "@material-ui/core"
import { GoogleAuthProvider } from "firebase/auth"
import React, { FunctionComponent } from "react"
import { GoogleThemeProvider } from "../../../theme/third-party/google"
import { PrimaryButton } from "../../button/PrimaryButton"
import { GoogleLoginIcon } from "./GoogleLoginIcon"
import { ThirdPartyLoginHandler } from "./ThirdPartyLoginHandler"
import { ThirdPartyLoginProvider } from "./ThirdPartyLoginProvider"

const googleAuthProvider = new GoogleAuthProvider()

export const createGoogleLoginButton =
  (login: ThirdPartyLoginHandler, loading: boolean): FunctionComponent =>
  () =>
    (
      <GoogleThemeProvider>
        <PrimaryButton
          startIcon={
            <SvgIcon>
              <GoogleLoginIcon />
            </SvgIcon>
          }
          loading={loading}
          shadowColor="#000000"
          onClick={() =>
            login(ThirdPartyLoginProvider.GOOGLE, googleAuthProvider)
          }
        >
          Continue with Google
        </PrimaryButton>
      </GoogleThemeProvider>
    )
