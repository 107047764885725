import { Form as FormikForm, Formik, FormikConfig, FormikValues } from "formik"
import { WithTranslateFormErrors } from "./translateFormErrors"

export function Form<
  Values extends FormikValues = FormikValues,
  ExtraProps = {}
>({ children, ...props }: FormikConfig<Values> & ExtraProps): JSX.Element {
  return (
    <Formik {...props}>
      <WithTranslateFormErrors>
        <FormikForm style={{ height: "inherit" }}>{children}</FormikForm>
      </WithTranslateFormErrors>
    </Formik>
  )
}
