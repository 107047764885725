import { Field, FieldAttributes } from "formik"
import {
  TextField as FormikTextField,
  TextFieldProps,
} from "formik-material-ui"
import { FunctionComponent } from "react"

const StyledTextField: FunctionComponent<TextFieldProps> = (
  props: TextFieldProps
) => <FormikTextField variant="outlined" fullWidth {...props} />

export const TextField: FunctionComponent<FieldAttributes<any>> = (props) => (
  <Field component={StyledTextField} {...props} />
)
