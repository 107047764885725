import Alert from "@material-ui/lab/Alert"
import React, { FunctionComponent } from "react"
import { useWebsiteName } from "../../../utils/useWebsiteName"
import { getThirdPartyLoginProviderName } from "./getThirdPartyLoginProviderName"
import { ThirdPartyLoginError } from "./ThirdPartyLoginError"
import { ThirdPartyLoginErrorType } from "./ThirdPartyLoginErrorType"
import { ThirdPartyLoginProvider } from "./ThirdPartyLoginProvider"

interface ThirdPartyLoginErrorAlertProps {
  type: ThirdPartyLoginErrorType
  error: ThirdPartyLoginError
  provider: ThirdPartyLoginProvider | null
}

export const ThirdPartyLoginErrorAlert: FunctionComponent<ThirdPartyLoginErrorAlertProps> =
  ({ type, error, provider }) => {
    if (provider === null || type === ThirdPartyLoginErrorType.POPUP_CLOSED) {
      return null
    }

    const providerName = getThirdPartyLoginProviderName(provider)

    if (type === ThirdPartyLoginErrorType.ACCOUNT_EXISTS_OTHER_PROVIDER) {
      const email = error?.customData.email ? (
        <strong>{error.customData.email}</strong>
      ) : (
        "this e-mail address"
      )

      const siteName = useWebsiteName()

      return (
        <Alert severity="error">
          Whoops! You tried to log in with {providerName} using an account that
          uses {email} as its e-mail address. You already have a {siteName}
          account using {email} as your e-mail address. You can only create one
          {siteName} account per e-mail address.
        </Alert>
      )
    }

    if (type === ThirdPartyLoginErrorType.POPUP_BLOCKED) {
      return (
        <Alert severity="error">
          Whoops! Looks like the pop-up that's needed for login with{" "}
          {providerName} was blocked by either your browser or an adblocker.
          Check if your browser has blocked any pop-ups or disable your
          adblocker.
        </Alert>
      )
    }

    if (type === ThirdPartyLoginErrorType.POPUP_CANCELLED) {
      return (
        <Alert severity="warning">
          Whoops! Another sign in pop-up is already opened. Complete or cancel
          all sign in processes before attempting a new one.
        </Alert>
      )
    }

    return (
      <Alert severity="error">
        Something went wrong while signing you in with {providerName}:{" "}
        {error?.message ?? error ?? "Error unknown."}
      </Alert>
    )
  }
