import { ThirdPartyLoginErrorType } from "./ThirdPartyLoginErrorType"

export const getThirdPartyLoginErrorFromFirebaseCode = (code?: string) => {
  switch (code) {
    case "auth/account-exists-with-different-credential":
      return ThirdPartyLoginErrorType.ACCOUNT_EXISTS_OTHER_PROVIDER
    case "auth/cancelled-popup-request":
      return ThirdPartyLoginErrorType.POPUP_CANCELLED
    case "auth/popup-blocked":
      return ThirdPartyLoginErrorType.POPUP_BLOCKED
    case "auth/popup-closed-by-user":
      return ThirdPartyLoginErrorType.POPUP_CLOSED
    default:
      return ThirdPartyLoginErrorType.OTHER
  }
}
