import { ThirdPartyLoginProvider } from "./ThirdPartyLoginProvider"

export const getThirdPartyLoginProviderName = (
  provider: ThirdPartyLoginProvider
): string => {
  switch (provider) {
    case ThirdPartyLoginProvider.FACEBOOK:
      return "Facebook"
    case ThirdPartyLoginProvider.GOOGLE:
      return "Google"
    case ThirdPartyLoginProvider.SSO:
      return "SSO"
  }
}
