import Link, { LinkProps } from "next/link"
import { FunctionComponent } from "react"
import { UrlObject } from "url"
import { useRedirect } from "./useRedirect"

interface RedirectLinkProps extends LinkProps {
  href: UrlObject | string
  keepRedirect?: boolean
}

export const RedirectLink: FunctionComponent<RedirectLinkProps> = ({
  href,
  keepRedirect,
  ...props
}) => {
  const { generateRedirectUrl } = useRedirect()

  return (
    <Link href={generateRedirectUrl(href, keepRedirect)} as={href} {...props} />
  )
}
