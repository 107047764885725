import Alert from "@material-ui/lab/Alert"
import Link from "next/link"
import React, { FunctionComponent } from "react"
import { useWebsiteName } from "../../../utils/useWebsiteName"
import { FlatLink } from "../../FlatLink"
import { LoginErrorType } from "./LoginErrorType"

interface LoginErrorAlertProps {
  type: LoginErrorType
  error?: string
}

export const LoginErrorAlert: FunctionComponent<LoginErrorAlertProps> = ({
  type,
  error,
}) => {
  if (type === LoginErrorType.NOT_FOUND) {
    const site_name = useWebsiteName()

    return (
      <Alert severity="warning">
        Whoops! Looks like you don't have a {site_name} account on that e-mail
        address. Did you want to{" "}
        <Link href="/register" passHref>
          <FlatLink>create an account</FlatLink>
        </Link>
        ?
      </Alert>
    )
  }

  if (type === LoginErrorType.PASSWORD_INVALID) {
    return (
      <Alert severity="error">
        Whoops! Looks like that's not the right password for this account.
      </Alert>
    )
  }

  if (
    type === LoginErrorType.EMAIL_INVALID ||
    type === LoginErrorType.USER_DISABLED
  ) {
    return <Alert severity="error">Whoops! {error}</Alert>
  }

  return (
    <Alert severity="error">
      Something went wrong while signing you in: {error}
    </Alert>
  )
}
