import { forwardRef, FunctionComponent } from "react"
import { StyledButton, StyledButtonProps } from "./StyledButton"

export const PrimaryButton: FunctionComponent<StyledButtonProps> = forwardRef(
  (props, ref) => (
    <StyledButton
      variant="contained"
      color="primary"
      size="large"
      ref={ref}
      fullWidth
      {...props}
    />
  )
)
