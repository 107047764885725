import { Facebook } from "@material-ui/icons"
import { FacebookAuthProvider } from "firebase/auth"
import React, { FunctionComponent } from "react"
import { FacebookThemeProvider } from "../../../theme/third-party/facebook"
import { PrimaryButton } from "../../button/PrimaryButton"
import { ThirdPartyLoginHandler } from "./ThirdPartyLoginHandler"
import { ThirdPartyLoginProvider } from "./ThirdPartyLoginProvider"

const facebookAuthProvider = new FacebookAuthProvider()

export const createFacebookLoginButton =
  (login: ThirdPartyLoginHandler, loading: boolean): FunctionComponent =>
  () =>
    (
      <FacebookThemeProvider>
        <PrimaryButton
          startIcon={<Facebook />}
          loading={loading}
          onClick={() =>
            login(ThirdPartyLoginProvider.FACEBOOK, facebookAuthProvider)
          }
        >
          Continue with Facebook
        </PrimaryButton>
      </FacebookThemeProvider>
    )
