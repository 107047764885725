import { Auth } from "firebase/auth"
import { useAuth } from "reactfire"

export const useFirebaseAuth: () => Auth = () => {
  const firebaseAuth = useAuth()

  if (firebaseAuth === null) {
    throw new Error("Firebase Auth was not initialized")
  }

  return firebaseAuth
}
