import { signInWithEmailAndPassword } from "firebase/auth"
import { FunctionComponent, useEffect, useState } from "react"
import * as Yup from "yup"
import { useFirebaseAuth } from "../../../utils/authentication/useFirebaseAuth"
import { useRedirect } from "../../../utils/routing/useRedirect"
import { SecondaryButton } from "../../button/SecondaryButton"
import { Form } from "../../form/Form"
import { FormState } from "../../form/FormState"
import { TextField } from "../../form/TextField"
import { ThirdPartyLoginState } from "../third-party/ThirdPartyLoginState"
import { useThirdPartyLoginElements } from "../third-party/useThirdPartyLoginElements"
import { getErrorFromFirebaseCode } from "./getLoginErrorFromFirebaseCode"
import { LoginErrorAlert } from "./LoginErrorAlert"
import { LoginErrorType } from "./LoginErrorType"

interface LoginInputs {
  email: string
  password: string
}

const loginDefaults: LoginInputs = {
  email: "",
  password: "",
}

const loginSchema = Yup.object().shape({
  email: Yup.string().label("E-mail").required().email(),
  password: Yup.string().label("Password").required().min(8),
})

export const useLoginFormElements = () => {
  const [loginState, setLoginState] = useState<FormState>(FormState.IDLE)
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>("")
  const [loginErrorType, setLoginErrorType] = useState<LoginErrorType>(
    LoginErrorType.OTHER
  )

  const firebaseAuth = useFirebaseAuth()
  const { executeRedirect } = useRedirect()

  const {
    FacebookLoginButton,
    GoogleLoginButton,
    SSOLoginButton,
    thirdPartyLoginState,
    resetThirdPartyLoginState,
    ThirdPartyLoginErrorAlert,
  } = useThirdPartyLoginElements()

  useEffect(() => {
    if (thirdPartyLoginState !== ThirdPartyLoginState.LOGGED_IN) {
      return
    }

    executeRedirect("/")
  }, [thirdPartyLoginState])

  const login = async (values: LoginInputs) => {
    resetThirdPartyLoginState()

    try {
      setLoginState(FormState.LOADING)

      await signInWithEmailAndPassword(
        firebaseAuth,
        values.email,
        values.password
      )

      setLoginState(FormState.SUBMITTED)
      executeRedirect("/")
    } catch (error) {
      setLoginState(FormState.ERROR)
      const errorType = getErrorFromFirebaseCode(error?.code)
      setLoginErrorType(errorType)
      setLoginErrorMessage(error?.message ?? error)
      console.error(error)
    }
  }

  const LoginForm: FunctionComponent = ({ children }) => (
    <Form
      initialValues={loginDefaults}
      validationSchema={loginSchema}
      onSubmit={login}
    >
      {children}
    </Form>
  )

  const EmailField: FunctionComponent = () => (
    <TextField label="E-mail" name="email" type="email" />
  )

  const PasswordField: FunctionComponent = () => (
    <TextField label="Password" name="password" type="password" />
  )

  const LoginButton: FunctionComponent = () => (
    <SecondaryButton type="submit" loading={loginState === FormState.LOADING}>
      Log in
    </SecondaryButton>
  )

  interface LoginErrorProps {
    wrapper: FunctionComponent
  }

  const LoginError: FunctionComponent<LoginErrorProps> = ({
    wrapper: Wrapper,
  }) => {
    if (
      loginState !== FormState.ERROR &&
      thirdPartyLoginState !== ThirdPartyLoginState.ERROR
    ) {
      return null
    }

    if (thirdPartyLoginState === ThirdPartyLoginState.ERROR) {
      return (
        <Wrapper>
          <ThirdPartyLoginErrorAlert />
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        <LoginErrorAlert type={loginErrorType} error={loginErrorMessage} />
      </Wrapper>
    )
  }

  return {
    LoginForm,
    EmailField,
    PasswordField,
    LoginButton,
    LoginError,
    FacebookLoginButton,
    GoogleLoginButton,
    SSOLoginButton,
  }
}
