import { LoginErrorType } from "./LoginErrorType"

export const getErrorFromFirebaseCode = (code?: string) => {
  switch (code) {
    case "auth/invalid-email":
      return LoginErrorType.EMAIL_INVALID
    case "auth/user-disabled":
      return LoginErrorType.USER_DISABLED
    case "auth/user-not-found":
      return LoginErrorType.NOT_FOUND
    case "auth/wrong-password":
      return LoginErrorType.PASSWORD_INVALID
    default:
      return LoginErrorType.OTHER
  }
}
