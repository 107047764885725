import { SvgIcon } from "@material-ui/core"
import { OAuthProvider } from "firebase/auth"
import React, { FunctionComponent } from "react"
import { SSOThemeProvider } from "../../../theme/third-party/sso"
import { PrimaryButton } from "../../button/PrimaryButton"
import { MicrosoftLoginIcon } from "./MicrosoftLoginIcon"
import { ThirdPartyLoginHandler } from "./ThirdPartyLoginHandler"
import { ThirdPartyLoginProvider } from "./ThirdPartyLoginProvider"

//TODO: adapt to azure ad
const ssoAuthProvider = new OAuthProvider("microsoft.com")
ssoAuthProvider.setCustomParameters({
  tenant: process.env.NEXT_PUBLIC_MICROSOFT_TENANT_ID!,
})

export const createMicrosoftLoginButton =
  (login: ThirdPartyLoginHandler, loading: boolean): FunctionComponent =>
  () =>
    (
      <SSOThemeProvider>
        <PrimaryButton
          startIcon={
            <SvgIcon>
              <MicrosoftLoginIcon />
            </SvgIcon>
          }
          loading={loading}
          shadowColor="#000000"
          onClick={() => login(ThirdPartyLoginProvider.SSO, ssoAuthProvider)}
        >
          Continue with SSO
        </PrimaryButton>
      </SSOThemeProvider>
    )
